import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useMemo, useState } from 'react';
import { IconStatusLikeFilled, IconStatusStarFilled, Select, SelectVariant, SelectWidthVariant, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "select"
    }}>{`Select`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Select } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=1091%3A190'} storybook={'/?path=/story/inputs-select--playground'} vueStorybook={'/?path=/story/inputs-select--playground'} name={'Select'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Select — это инпут с возможностью выбора одной или нескольких опций из списка.
Компонент можно использовать как в формах, так и самостоятельно.`}</p>
    <p>{`Набор опций передается компоненту через проп `}<inlineCode parentName="p">{`options`}</inlineCode>{` в виде массива объектов типа `}<inlineCode parentName="p">{`ISelectOption`}</inlineCode>{`.
Каждый такой объект должен содержать строковые поля `}<inlineCode parentName="p">{`label`}</inlineCode>{` (отображаемый текст) и `}<inlineCode parentName="p">{`value`}</inlineCode>{` (скрытое значение).
Обратите внимание, что численные `}<inlineCode parentName="p">{`value`}</inlineCode>{` не поддерживаются. Также каждая опция должна иметь уникальное значение `}<inlineCode parentName="p">{`value`}</inlineCode>{`.`}</p>
    <p>{`У объектов типа `}<inlineCode parentName="p">{`ISelectOption`}</inlineCode>{` могут также быть дополнительные атрибуты, задающие расширенное поведение:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`export interface ISelectOption {
  /**
   * Отображаемое название
   */
  label: string;
  /**
   * Уникальный идентификатор (значение) опции
   */
  value: string;
  /**
   * Неактивная опция
   */
  inactive?: boolean;
  /**
   * Добавляет разделитель перед опцией
   */
  divided?: boolean;
  /**
   * Идентификатор группы опций.
   * Предназначен для внутреннего использования.
   * Для задания группировки опций используйте интерфейс IOptionGroup вместо IOption.
   */
  group?: string;
  /**
   * Левая иконка
   */
  leftIcon?: React.ElementType;
  /**
   * Правая иконка
   */
  rightIcon?: React.ElementType;
}
`}</code></pre>
    <p>{`У компонента есть возможность отображать пользователю поле поиска.
Для этого передайте проп `}<inlineCode parentName="p">{`searchable`}</inlineCode>{` со значением `}<inlineCode parentName="p">{`inline`}</inlineCode>{` или `}<inlineCode parentName="p">{`dropdown`}</inlineCode>{`. Подробнее `}<a parentName="p" {...{
        "href": "#%D0%BF%D0%BE%D0%B8%D1%81%D0%BA"
      }}>{`в примере ниже`}</a>{`.`}</p>
    <p>{`Для режима множественного выбора, передайте проп `}<inlineCode parentName="p">{`multiple`}</inlineCode>{` равный `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <p>{`Поддерживается только контролируемое использование. Для управления выбранным значением передайте проп `}<inlineCode parentName="p">{`value`}</inlineCode>{` и обрабатывайте событие `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`.
Проп `}<inlineCode parentName="p">{`value`}</inlineCode>{` может содержать `}<inlineCode parentName="p">{`null`}</inlineCode>{`, строку-значение, объект-опцию (`}<inlineCode parentName="p">{`ISelectOption`}</inlineCode>{`), либо, в режиме `}<inlineCode parentName="p">{`multiple`}</inlineCode>{`, массив строк-значений или объектов-опций.`}</p>
    <p>{`Если массив `}<inlineCode parentName="p">{`options`}</inlineCode>{` слишком большой или полностью неизвестен, можно использовать вариант селекта с динамической загрузкой опций.
В режиме динамической загрузки опций для корректной работы используйте `}<inlineCode parentName="p">{`value`}</inlineCode>{` в виде объекта-опции либо массива объектов-опций,
а вместо события `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` - событие `}<inlineCode parentName="p">{`onChangeOption`}</inlineCode>{`, принимающее аргументом не значения, а целые объекты-опции.
Подробнее `}<a parentName="p" {...{
        "href": "#%D0%B4%D0%B8%D0%BD%D0%B0%D0%BC%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8-%D0%B7%D0%B0%D0%B3%D1%80%D1%83%D0%B6%D0%B0%D0%B5%D0%BC%D1%8B%D0%B5-%D0%BE%D0%BF%D1%86%D0%B8%D0%B8"
      }}>{`в примере ниже`}</a>{`.`}</p>
    <p>{`У компонента есть два варианта отображения: `}<inlineCode parentName="p">{`default`}</inlineCode>{` и `}<inlineCode parentName="p">{`borderless`}</inlineCode>{`. Вариант отображения задается пропом `}<inlineCode parentName="p">{`variant`}</inlineCode>{`. Подробнее о них написано `}<a parentName="p" {...{
        "href": "#%D0%B2%D0%B0%D1%80%D0%B8%D0%B0%D0%BD%D1%82%D1%8B-%D0%BE%D1%82%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F"
      }}>{`в примере ниже`}</a>{`.`}</p>
    <p>{`Для добавления возможности очистки текущего значения передайте проп `}<inlineCode parentName="p">{`clearable`}</inlineCode>{`.
В таком случае отображается кнопка-крестик, при нажатии на которую вызывается событие `}<inlineCode parentName="p">{`onClear`}</inlineCode>{`,
а при отсутствии колбека для него - `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` и `}<inlineCode parentName="p">{`onChangeOption`}</inlineCode>{`.
Аргументом передается `}<inlineCode parentName="p">{`null`}</inlineCode>{` или, в режиме `}<inlineCode parentName="p">{`multiple`}</inlineCode>{`, пустой массив.`}</p>
    <p>{`Ширина компонента задается пропом `}<inlineCode parentName="p">{`width`}</inlineCode>{`. Вы можете передать число, оно преобразуется в пиксели.
С вариантом `}<inlineCode parentName="p">{`auto`}</inlineCode>{` компонент расширяется в зависимости от размера значения, вариант `}<inlineCode parentName="p">{`full`}</inlineCode>{` делает `}<inlineCode parentName="p">{`100%`}</inlineCode>{` ширину.`}</p>
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = useState(\'1\')\n  const options = useMemo(\n    () => [\n      { label: \'First\', value: \'1\', leftIcon: IconStatusLikeFilled },\n      { label: \'Second\', value: \'2\' },\n      { label: \'Third\', value: \'3\', rightIcon: IconStatusStarFilled },\n      { label: \'Fourth\', value: \'4\' },\n      { label: \'Fifth\', value: \'5\' },\n      { label: \'Sixth\', value: \'6\' },\n      { label: \'Seventh\', value: \'7\' },\n      { label: \'Eighth\', value: \'8\' },\n      { label: \'Ninth\', value: \'9\' },\n      { label: \'Tenth\', value: \'10\' },\n    ],\n    [],\n  )\n  return (\n    <Select value={value} clearable onChange={setValue} options={options} />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      IconStatusLikeFilled,
      IconStatusStarFilled,
      Select,
      SelectVariant,
      SelectWidthVariant,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('1');
        const options = useMemo(() => [{
          label: 'First',
          value: '1',
          leftIcon: IconStatusLikeFilled
        }, {
          label: 'Second',
          value: '2'
        }, {
          label: 'Third',
          value: '3',
          rightIcon: IconStatusStarFilled
        }, {
          label: 'Fourth',
          value: '4'
        }, {
          label: 'Fifth',
          value: '5'
        }, {
          label: 'Sixth',
          value: '6'
        }, {
          label: 'Seventh',
          value: '7'
        }, {
          label: 'Eighth',
          value: '8'
        }, {
          label: 'Ninth',
          value: '9'
        }, {
          label: 'Tenth',
          value: '10'
        }], []);
        return <Select value={value} clearable onChange={setValue} options={options} mdxType="Select" />;
      }}
    </Playground>
    <h2 {...{
      "id": "поведение"
    }}>{`Поведение`}</h2>
    <FigmaEmbed node={'?node-id=8113%3A17'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node={'?node-id=8113%3A25'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node={'?node-id=8113%3A21'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "варианты-отображения"
    }}>{`Варианты отображения`}</h3>
    <p>{`У компонента есть два варианта отображения: `}<inlineCode parentName="p">{`default`}</inlineCode>{` и `}<inlineCode parentName="p">{`borderless`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`borderless`}</inlineCode>{` вариант используется, когда рамка имеет слишком большой визуальный вес.
Например, выбор языка в хедере.
Этот вариант не может быть мультиселектом или иметь встроенный поиск (допустим только поиск в дропдауне).
Рекомендуется использовать его только в тех случаях, когда набор вариантов ограничен.`}</p>
    <p><inlineCode parentName="p">{`default`}</inlineCode>{` вариант используется по умолчанию. Подходит для всех остальных случаев`}</p>
    <Playground __position={5} __code={'() => {\n  const [value, setValue] = useState(null)\n  const [language, setLanguage] = useState(\'RU\')\n  const options1 = useMemo(\n    () => [\n      { label: \'First\', value: \'1\', leftIcon: IconStatusLikeFilled },\n      { label: \'Second\', value: \'2\', rightIcon: IconStatusStarFilled },\n    ],\n    [],\n  )\n  const options2 = useMemo(\n    () => [\n      { label: \'RU\', value: \'RU\' },\n      { label: \'EN\', value: \'EN\' },\n    ],\n    [],\n  )\n  return (\n    <div style={{ display: \'flex\' }}>\n      <Select value={value} onChange={setValue} options={options1} />\n      <Space horizontal={true} size={Size.small_x} />\n      <Select\n        width={150}\n        value={language}\n        onChange={setLanguage}\n        options={options2}\n        variant={SelectVariant.borderless}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      IconStatusLikeFilled,
      IconStatusStarFilled,
      Select,
      SelectVariant,
      SelectWidthVariant,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState(null);
        const [language, setLanguage] = useState('RU');
        const options1 = useMemo(() => [{
          label: 'First',
          value: '1',
          leftIcon: IconStatusLikeFilled
        }, {
          label: 'Second',
          value: '2',
          rightIcon: IconStatusStarFilled
        }], []);
        const options2 = useMemo(() => [{
          label: 'RU',
          value: 'RU'
        }, {
          label: 'EN',
          value: 'EN'
        }], []);
        return <div style={{
          display: 'flex'
        }}>
        <Select value={value} onChange={setValue} options={options1} mdxType="Select" />
        <Space horizontal={true} size={Size.small_x} mdxType="Space" />
        <Select width={150} value={language} onChange={setLanguage} options={options2} variant={SelectVariant.borderless} mdxType="Select" />
      </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "поиск"
    }}>{`Поиск`}</h3>
    <p>{`Для больших списков значений рекомендуется давать пользователю возможность поиска с клавиатуры.
Для отображения поля поиска передайте проп `}<inlineCode parentName="p">{`searchable`}</inlineCode>{` со значением `}<inlineCode parentName="p">{`inline`}</inlineCode>{` или `}<inlineCode parentName="p">{`dropdown`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Значение `}<inlineCode parentName="p">{`inline`}</inlineCode>{` отображает поиск в основном контейнере (этот вариант нельзя использовать совместно с `}<inlineCode parentName="p">{`multiple`}</inlineCode>{`)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Значение `}<inlineCode parentName="p">{`dropdown`}</inlineCode>{` отображает поиск во всплывающем меню (этот вариант можно использовать совместно с `}<inlineCode parentName="p">{`multiple`}</inlineCode>{`)`}</p>
      </li>
    </ul>
    <p>{`При использовании варианта `}<inlineCode parentName="p">{`dropdown`}</inlineCode>{`, вы можете контролировать текст плейсхолдера поля поиска
с помощью пропа `}<inlineCode parentName="p">{`searchPlaceholder`}</inlineCode>{`.`}</p>
    <Playground __position={6} __code={'() => {\n  const [value, setValue] = useState(\'1\')\n  const options = useMemo(\n    () => [\n      { label: \'First\', value: \'1\', leftIcon: IconStatusLikeFilled },\n      { label: \'Second\', value: \'2\' },\n      { label: \'Third\', value: \'3\', rightIcon: IconStatusStarFilled },\n      { label: \'Fourth\', value: \'4\' },\n      { label: \'Fifth\', value: \'5\' },\n      { label: \'Sixth\', value: \'6\' },\n      { label: \'Seventh\', value: \'7\' },\n      { label: \'Eighth\', value: \'8\' },\n      { label: \'Ninth\', value: \'9\' },\n      { label: \'Tenth\', value: \'10\' },\n    ],\n    [],\n  )\n  return (\n    <>\n      <Select\n        searchable={Select.Searchable.inline}\n        value={value}\n        clearable\n        onChange={setValue}\n        options={options}\n      />\n      <Space size={Space.Size.small} vertical />\n      <Select\n        searchable=\"dropdown\"\n        value={value}\n        clearable\n        onChange={setValue}\n        options={options}\n        searchPlaceholder=\"Поиск значения\"\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      IconStatusLikeFilled,
      IconStatusStarFilled,
      Select,
      SelectVariant,
      SelectWidthVariant,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('1');
        const options = useMemo(() => [{
          label: 'First',
          value: '1',
          leftIcon: IconStatusLikeFilled
        }, {
          label: 'Second',
          value: '2'
        }, {
          label: 'Third',
          value: '3',
          rightIcon: IconStatusStarFilled
        }, {
          label: 'Fourth',
          value: '4'
        }, {
          label: 'Fifth',
          value: '5'
        }, {
          label: 'Sixth',
          value: '6'
        }, {
          label: 'Seventh',
          value: '7'
        }, {
          label: 'Eighth',
          value: '8'
        }, {
          label: 'Ninth',
          value: '9'
        }, {
          label: 'Tenth',
          value: '10'
        }], []);
        return <>
      <Select searchable={Select.Searchable.inline} value={value} clearable onChange={setValue} options={options} mdxType="Select" />
      <Space size={Space.Size.small} vertical mdxType="Space" />
      <Select searchable="dropdown" value={value} clearable onChange={setValue} options={options} searchPlaceholder="Поиск значения" mdxType="Select" />
    </>;
      }}
    </Playground>
    <h3 {...{
      "id": "динамически-загружаемые-опции"
    }}>{`Динамически загружаемые опции`}</h3>
    <p>{`Если массив `}<inlineCode parentName="p">{`options`}</inlineCode>{` слишком большой или полностью неизвестен, можно использовать вариант селекта с динамической загрузкой опций.
Для этого вместо пропа `}<inlineCode parentName="p">{`options`}</inlineCode>{` передайте колбек в проп `}<inlineCode parentName="p">{`getOptions`}</inlineCode>{`, а также включите поиск, передав одно из возможных значений `}<inlineCode parentName="p">{`searchable`}</inlineCode>{`.`}</p>
    <p>{`При каждом обновлении поисковой строки будет вызван переданный колбек.
Единственным аргументом ему передаётся значение поисковой строки.
Колбек должен вернуть промис с массивом опций, соответствующих поисковой строке.`}</p>
    <p>{`При использовании `}<inlineCode parentName="p">{`getOptions`}</inlineCode>{` встроенная фильтрация не применяется и отдаётся на усмотрение конкретной реализации,
так как основной предполагаемый вариант использования `}<inlineCode parentName="p">{`getOptions`}</inlineCode>{` - выполнение запросов на бекенд для получения уже отфильтрованного списка опций.`}</p>
    <p>{`Если запрос с бекенда возвращает полный список опций или не поддерживает фильтрацию, то рекомендуется
не использовать `}<inlineCode parentName="p">{`getOptions`}</inlineCode>{`, предварительно загрузить весь список опций с бекенда и передать его в виде статического массива в проп `}<inlineCode parentName="p">{`options`}</inlineCode>{`.`}</p>
    <p>{`При работе с `}<inlineCode parentName="p">{`getOptions`}</inlineCode>{` пользователю отображаются различные сообщения, которые можно настраивать с помощью следующих пропов:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`nothingFoundPlaceholder`}</inlineCode>{` – текст сообщения, отображаемого в случае, если не найдено опций, удовлетворяющих запросу для поиска`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`loadingPlaceholder`}</inlineCode>{` – текст сообщения, отображаемого во время динамической загрузки опций`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`defaultErrorMessage`}</inlineCode>{` – текст сообщения, отображаемого в случае возникновения ошибки в `}<inlineCode parentName="li">{`getOptions`}</inlineCode>{`, если возникшая ошибка не содержит `}<inlineCode parentName="li">{`message`}</inlineCode></li>
    </ul>
    <p>{`Пропом `}<inlineCode parentName="p">{`searchPlaceholder`}</inlineCode>{` можно задать текст `}<inlineCode parentName="p">{`placeholder`}</inlineCode>{` для поля поиска.`}</p>
    <p>{`При использовании динамической загрузки опций используйте объекты `}<inlineCode parentName="p">{`value`}</inlineCode>{` типа `}<inlineCode parentName="p">{`ISelectOption`}</inlineCode>{` (или `}<inlineCode parentName="p">{`ISelectOption[]`}</inlineCode>{` для `}<inlineCode parentName="p">{`multiple`}</inlineCode>{`) и колбек `}<inlineCode parentName="p">{`onChangeOption`}</inlineCode>{` вместо `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`.
В противном случае для значений из `}<inlineCode parentName="p">{`value`}</inlineCode>{`, отсутствующих в результате функции `}<inlineCode parentName="p">{`getOptions`}</inlineCode>{`, `}<inlineCode parentName="p">{`label`}</inlineCode>{` будет неизвестен и не будет отображаться.`}</p>
    <Playground __position={7} __code={'() => {\n  const [value, setValue] = useState({ label: \'First\', value: 1 })\n  const options = useMemo(\n    () => [\n      { label: \'First\', value: \'1\', leftIcon: IconStatusLikeFilled },\n      { label: \'Second\', value: \'2\' },\n      { label: \'Third\', value: \'3\', rightIcon: IconStatusStarFilled },\n      { label: \'Fourth\', value: \'4\' },\n      { label: \'Fifth\', value: \'5\' },\n      { label: \'Sixth\', value: \'6\' },\n      { label: \'Seventh\', value: \'7\' },\n      { label: \'Eighth\', value: \'8\' },\n      { label: \'Ninth\', value: \'9\' },\n      { label: \'Tenth\', value: \'10\' },\n    ],\n    [],\n  )\n  return (\n    <Select\n      value={value}\n      clearable\n      onChangeOption={setValue}\n      searchable={Select.Searchable.inline}\n      getOptions={searchValue => {\n        return new Promise(resolve => {\n          if (searchValue) {\n            // Эмулируем запрос к бекенду через setTimeout\n            setTimeout(\n              () =>\n                resolve(\n                  options.filter(opt => opt.label.includes(searchValue)),\n                ),\n              500,\n            )\n          } else {\n            resolve(options)\n          }\n        })\n      }}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      IconStatusLikeFilled,
      IconStatusStarFilled,
      Select,
      SelectVariant,
      SelectWidthVariant,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState({
          label: 'First',
          value: 1
        });
        const options = useMemo(() => [{
          label: 'First',
          value: '1',
          leftIcon: IconStatusLikeFilled
        }, {
          label: 'Second',
          value: '2'
        }, {
          label: 'Third',
          value: '3',
          rightIcon: IconStatusStarFilled
        }, {
          label: 'Fourth',
          value: '4'
        }, {
          label: 'Fifth',
          value: '5'
        }, {
          label: 'Sixth',
          value: '6'
        }, {
          label: 'Seventh',
          value: '7'
        }, {
          label: 'Eighth',
          value: '8'
        }, {
          label: 'Ninth',
          value: '9'
        }, {
          label: 'Tenth',
          value: '10'
        }], []);
        return <Select value={value} clearable onChangeOption={setValue} searchable={Select.Searchable.inline} getOptions={searchValue => {
          return new Promise(resolve => {
            if (searchValue) {
              // Эмулируем запрос к бекенду через setTimeout
              setTimeout(() => resolve(options.filter(opt => opt.label.includes(searchValue))), 500);
            } else {
              resolve(options);
            }
          });
        }} mdxType="Select" />;
      }}
    </Playground>
    <h3 {...{
      "id": "множественный-выбор"
    }}>{`Множественный выбор`}</h3>
    <p>{`Для добавления множественного выбора, передайте проп `}<inlineCode parentName="p">{`multiple`}</inlineCode>{`.
Выбранные опции в инпуте будут отображаться тегами.`}</p>
    <p>{`В таком случае `}<inlineCode parentName="p">{`value`}</inlineCode>{` должен быть массивом значений или массивом выбранных объектов-опций.
В `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` также передаются массивы.
Если ничего не выбрано, то значением должен быть пустой массив.`}</p>
    <p>{`По умолчанию выбранные опции поднимаются наверх.
Передайте значение `}<inlineCode parentName="p">{`false`}</inlineCode>{` в проп `}<inlineCode parentName="p">{`selectedFirst`}</inlineCode>{`, чтобы отключить это поведение`}</p>
    <Playground __position={8} __code={'() => {\n  const [value, setValue] = useState([])\n  const options = useMemo(\n    () => [\n      { label: \'First\', value: \'1\', leftIcon: IconStatusLikeFilled },\n      { label: \'Second\', value: \'2\' },\n      { label: \'Third\', value: \'3\', rightIcon: IconStatusStarFilled },\n      { label: \'Fourth\', value: \'4\' },\n      { label: \'Fifth\', value: \'5\' },\n      { label: \'Sixth\', value: \'6\' },\n      { label: \'Seventh\', value: \'7\' },\n      { label: \'Eighth\', value: \'8\' },\n      { label: \'Ninth\', value: \'9\' },\n      { label: \'Tenth\', value: \'10\' },\n    ],\n    [],\n  )\n  return (\n    <Select\n      multiple\n      value={value}\n      clearable\n      onChange={setValue}\n      options={options}\n      placeholder=\"Выберите несколько значений\"\n      selectedFirst={true}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      IconStatusLikeFilled,
      IconStatusStarFilled,
      Select,
      SelectVariant,
      SelectWidthVariant,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState([]);
        const options = useMemo(() => [{
          label: 'First',
          value: '1',
          leftIcon: IconStatusLikeFilled
        }, {
          label: 'Second',
          value: '2'
        }, {
          label: 'Third',
          value: '3',
          rightIcon: IconStatusStarFilled
        }, {
          label: 'Fourth',
          value: '4'
        }, {
          label: 'Fifth',
          value: '5'
        }, {
          label: 'Sixth',
          value: '6'
        }, {
          label: 'Seventh',
          value: '7'
        }, {
          label: 'Eighth',
          value: '8'
        }, {
          label: 'Ninth',
          value: '9'
        }, {
          label: 'Tenth',
          value: '10'
        }], []);
        return <Select multiple value={value} clearable onChange={setValue} options={options} placeholder="Выберите несколько значений" selectedFirst={true} mdxType="Select" />;
      }}
    </Playground>
    <h3 {...{
      "id": "иконка-опции"
    }}>{`Иконка опции`}</h3>
    <p>{`Для отображения иконки рядом с опцией передайте свойство `}<inlineCode parentName="p">{`leftIcon`}</inlineCode>{` или `}<inlineCode parentName="p">{`rightIcon`}</inlineCode>{` с компонентом иконки.`}</p>
    <Playground __position={9} __code={'() => {\n  const [value, setValue] = useState(\'1\')\n  return (\n    <Select\n      value={value}\n      clearable\n      onChange={setValue}\n      options={[\n        { label: \'First\', value: \'1\', leftIcon: IconStatusLikeFilled },\n        { label: \'Second\', value: \'2\', rightIcon: IconStatusStarFilled },\n      ]}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      IconStatusLikeFilled,
      IconStatusStarFilled,
      Select,
      SelectVariant,
      SelectWidthVariant,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('1');
        return <Select value={value} clearable onChange={setValue} options={[{
          label: 'First',
          value: '1',
          leftIcon: IconStatusLikeFilled
        }, {
          label: 'Second',
          value: '2',
          rightIcon: IconStatusStarFilled
        }]} mdxType="Select" />;
      }}
    </Playground>
    <h3 {...{
      "id": "отключенное-состояние"
    }}>{`Отключенное состояние`}</h3>
    <p>{`Отключить `}<inlineCode parentName="p">{`Select`}</inlineCode>{` для ввода можно с помощью пропа `}<inlineCode parentName="p">{`disabled`}</inlineCode></p>
    <Playground __position={10} __code={'<Select disabled />'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      IconStatusLikeFilled,
      IconStatusStarFilled,
      Select,
      SelectVariant,
      SelectWidthVariant,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Select disabled mdxType="Select" />
    </Playground>
    <h3 {...{
      "id": "ширина-select"
    }}>{`Ширина Select`}</h3>
    <p>{`Ширина компонента задается пропом `}<inlineCode parentName="p">{`width`}</inlineCode>{`. Вы можете передать число, оно преобразуется в пиксели.
С вариантом `}<inlineCode parentName="p">{`auto`}</inlineCode>{` компонент расширяется в зависимости от ширины лейбла опции, вариант `}<inlineCode parentName="p">{`full`}</inlineCode>{` делает `}<inlineCode parentName="p">{`100%`}</inlineCode>{` ширину.`}</p>
    <Playground __position={11} __code={'() => {\n  const [value, setValue] = useState(\'1\')\n  const options = useMemo(\n    () => [\n      { label: \'First\', value: \'1\', leftIcon: IconStatusLikeFilled },\n      { label: \'Second\', value: \'2\' },\n      { label: \'Third\', value: \'3\', rightIcon: IconStatusStarFilled },\n      { label: \'Fourth\', value: \'4\' },\n      { label: \'Fifth\', value: \'5\' },\n      { label: \'Sixth\', value: \'6\' },\n      { label: \'Seventh\', value: \'7\' },\n      { label: \'Eighth\', value: \'8\' },\n      { label: \'Ninth\', value: \'9\' },\n      { label: \'Tenth\', value: \'10\' },\n    ],\n    [],\n  )\n  return (\n    <>\n      <Select\n        width={280}\n        options={options}\n        value={value}\n        onChange={setValue}\n      />\n      <Space size={Size.small_2x} vertical />\n      <Select\n        width={SelectWidthVariant.auto}\n        options={options}\n        value={value}\n        onChange={setValue}\n      />\n      <Space size={Size.small_2x} vertical />\n      <Select\n        width={SelectWidthVariant.full}\n        options={options}\n        value={value}\n        onChange={setValue}\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      IconStatusLikeFilled,
      IconStatusStarFilled,
      Select,
      SelectVariant,
      SelectWidthVariant,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      {() => {
        const [value, setValue] = useState('1');
        const options = useMemo(() => [{
          label: 'First',
          value: '1',
          leftIcon: IconStatusLikeFilled
        }, {
          label: 'Second',
          value: '2'
        }, {
          label: 'Third',
          value: '3',
          rightIcon: IconStatusStarFilled
        }, {
          label: 'Fourth',
          value: '4'
        }, {
          label: 'Fifth',
          value: '5'
        }, {
          label: 'Sixth',
          value: '6'
        }, {
          label: 'Seventh',
          value: '7'
        }, {
          label: 'Eighth',
          value: '8'
        }, {
          label: 'Ninth',
          value: '9'
        }, {
          label: 'Tenth',
          value: '10'
        }], []);
        return <>
          <Select width={280} options={options} value={value} onChange={setValue} mdxType="Select" />
          <Space size={Size.small_2x} vertical mdxType="Space" />
          <Select width={SelectWidthVariant.auto} options={options} value={value} onChange={setValue} mdxType="Select" />
          <Space size={Size.small_2x} vertical mdxType="Space" />
          <Select width={SelectWidthVariant.full} options={options} value={value} onChange={setValue} mdxType="Select" />
      </>;
      }}
    </Playground>
    <h3 {...{
      "id": "разделители-опций"
    }}>{`Разделители опций`}</h3>
    <p>{`Между опциями можно отображать разделители.
Для этого передайте свойство `}<inlineCode parentName="p">{`divided`}</inlineCode>{` со значением `}<inlineCode parentName="p">{`true`}</inlineCode>{` той опции, перед которой нужен разделитель.`}</p>
    <Playground __position={12} __code={'() => {\n  const [value, setValue] = useState(null)\n  const options = useMemo(\n    () => [\n      { label: \'First\', value: \'1\', leftIcon: IconStatusLikeFilled },\n      { label: \'Second\', value: \'2\', inactive: true },\n      { label: \'Third\', value: \'3\', rightIcon: IconStatusStarFilled },\n      { label: \'Fourth\', value: \'4\' },\n      { label: \'Fifth\', value: \'5\' },\n      { label: \'Sixth\', value: \'6\', divided: true },\n      { label: \'Seventh\', value: \'7\' },\n      { label: \'Eighth\', value: \'8\' },\n      { label: \'Ninth\', value: \'9\', divided: true },\n      { label: \'Tenth\', value: \'10\' },\n    ],\n    [],\n  )\n  return (\n    <Select value={value} clearable onChange={setValue} options={options} />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      IconStatusLikeFilled,
      IconStatusStarFilled,
      Select,
      SelectVariant,
      SelectWidthVariant,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState(null);
        const options = useMemo(() => [{
          label: 'First',
          value: '1',
          leftIcon: IconStatusLikeFilled
        }, {
          label: 'Second',
          value: '2',
          inactive: true
        }, {
          label: 'Third',
          value: '3',
          rightIcon: IconStatusStarFilled
        }, {
          label: 'Fourth',
          value: '4'
        }, {
          label: 'Fifth',
          value: '5'
        }, {
          label: 'Sixth',
          value: '6',
          divided: true
        }, {
          label: 'Seventh',
          value: '7'
        }, {
          label: 'Eighth',
          value: '8'
        }, {
          label: 'Ninth',
          value: '9',
          divided: true
        }, {
          label: 'Tenth',
          value: '10'
        }], []);
        return <Select value={value} clearable onChange={setValue} options={options} mdxType="Select" />;
      }}
    </Playground>
    <h3 {...{
      "id": "группы-опций"
    }}>{`Группы опций`}</h3>
    <p>{`Опции можно группировать в логически выделенные блоки.
Для этого передайте в `}<inlineCode parentName="p">{`options`}</inlineCode>{` массив, содержащий объекты-группы типа `}<inlineCode parentName="p">{`IOptionGroup`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`export interface IOptionGroup {
  /**
   * Название группы
   */
  label: string;
  /**
   * Массив опций группы
   */
  options: ISelectOption[];
}
`}</code></pre>
    <p>{`Опции без групп будут отображены в верхней части списка.`}</p>
    <p>{`Группировка не поддерживается в динамическом режиме при использовании `}<inlineCode parentName="p">{`getOptions`}</inlineCode>{`.`}</p>
    <Playground __position={13} __code={'() => {\n  const [value, setValue] = useState(null)\n  const options = useMemo(\n    () => [\n      {\n        label: \'Group 1\',\n        options: [\n          { label: \'First\', value: \'1\', leftIcon: IconStatusLikeFilled },\n          { label: \'Second\', value: \'2\', inactive: true },\n          { label: \'Third\', value: \'3\', rightIcon: IconStatusStarFilled },\n          { label: \'Fourth\', value: \'4\' },\n        ],\n      },\n      {\n        label: \'Group 2\',\n        options: [\n          { label: \'Fifth\', value: \'5\' },\n          { label: \'Sixth\', value: \'6\' },\n          { label: \'Seventh\', value: \'7\' },\n          { label: \'Eighth\', value: \'8\' },\n        ],\n      },\n      { label: \'Ninth\', value: \'9\' },\n      { label: \'Tenth\', value: \'10\' },\n    ],\n    [],\n  )\n  return (\n    <Select value={value} clearable onChange={setValue} options={options} />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      IconStatusLikeFilled,
      IconStatusStarFilled,
      Select,
      SelectVariant,
      SelectWidthVariant,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState(null);
        const options = useMemo(() => [{
          label: 'Group 1',
          options: [{
            label: 'First',
            value: '1',
            leftIcon: IconStatusLikeFilled
          }, {
            label: 'Second',
            value: '2',
            inactive: true
          }, {
            label: 'Third',
            value: '3',
            rightIcon: IconStatusStarFilled
          }, {
            label: 'Fourth',
            value: '4'
          }]
        }, {
          label: 'Group 2',
          options: [{
            label: 'Fifth',
            value: '5'
          }, {
            label: 'Sixth',
            value: '6'
          }, {
            label: 'Seventh',
            value: '7'
          }, {
            label: 'Eighth',
            value: '8'
          }]
        }, {
          label: 'Ninth',
          value: '9'
        }, {
          label: 'Tenth',
          value: '10'
        }], []);
        return <Select value={value} clearable onChange={setValue} options={options} mdxType="Select" />;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Select} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на скрытый элемент `}<inlineCode parentName="p">{`select`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      